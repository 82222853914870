<template>
  <el-container>
    <Menu ref="menu" :menu="labelsTree" :active="active" />
    <Main :menu="issuesTree" :active="active" />
  </el-container>
</template>

<script>
import { Container } from 'element-ui'
import Main from './components/main.vue'
import Menu from './components/menu.vue'

import { labelsToTree, issuesParse } from '@/utils'
import { getLabels, getIssues } from '@/api'

export default {
  name: 'Index',
  components: {
    [Container.name]: Container,
    Main,
    Menu
  },

  data () {
    return {
      labelsTree: [],
      issuesTree: []
    }
  },

  computed: {
    active () {
      return decodeURIComponent(location.hash)
    }
  },
  created () {
    this.init()
  },

  methods: {
    async init () {
      let labels
      let issues
      const isProd = process.env.NODE_ENV === 'production'
      if (isProd) {
        const db = await import('../../../db.json')
        labels = db.labels
        issues = db.issues
      } else {
        labels = await getLabels()
        issues = await getIssues()
        issues = issuesParse(JSON.parse(JSON.stringify(issues)))
      }

      const { tree: labelsTree } = labelsToTree(JSON.parse(JSON.stringify(labels)))
      this.labelsTree = labelsTree

      const issuesTree = []
      labelsTree.forEach(item => {
        const { children } = item
        if (children && children.length > 0) {
          children.forEach(item => {
            issuesTree.push({ ...item })
          })
        } else {
          issuesTree.push({ ...item })
        }
      })
      const map = new Map()
      issuesTree.forEach(i => {
        map[i.id] = i
      })

      issues.forEach(item => {
        const id = item.categoryId
        if (id) {
          if (!map[id].web) {
            map[id].web = [item]
          } else {
            map[id].web.push(item)
          }
        }
      })

      this.issuesTree = issuesTree
    }
  }
}
</script>

<style lang="less" scoped>
.el-container {
  height: 100%;
  @media screen and (max-width: @xs) {
    position: relative;
    flex-direction: column;
  }
}
.el-main {
  box-sizing: border-box;
}
</style>
