<template>
  <div class="main">
    <el-scrollbar>
      <!-- <div class="header">
        <el-button @click="login">登录</el-button>
      </div> -->
      <div class="content">
        <!-- <SearchTab /> -->
        <template v-for="item in menu">
          <div v-if="item.web && item.web.length > 0 || item.id === 4375917345" :id="item.name" :key="item.id" class="item">
            <div class="title">
              <!-- <i :class="item.icon" class="icon"></i> -->
              {{ item.name }}
            </div>

            <el-row>
              <el-col v-if="item.id === 4375917345" class="web-item">
                <div class="web-item-inner custom">
                  <GoogleAd v-if="showAd" />
                </div>
              </el-col>

              <el-col v-for="i in item.web" :key="i.id" class="web-item">
                <MainItem :item="i" />
              </el-col>
            </el-row>
            <br>
          </div>
        </template>
      </div>
    </el-scrollbar>
    <!-- <div>👋Hi, Tahlia!</div> -->

    <!-- TODO: 搜索 -->

    <!-- TODO: 推广 -->
  </div>
</template>

<script>
import { Scrollbar, Button, Row, Col } from 'element-ui'
import GoogleAd from '@/components/ad'
import MainItem from './main-item.vue'
// import SearchTab from './search-tab.vue'

import oauthProvider from '@/utils/config'

export default {
  components: {
    [Scrollbar.name]: Scrollbar,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    GoogleAd,
    MainItem
    // SearchTab
  },
  props: {
    menu: {
      type: Array,
      default: () => {}
    },
    active: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showAd: false
    }
  },
  watch: {
    menu: {
      handler () {
        this.$nextTick(() => {
          if (!this.active) return
          const dom = document.querySelector(this.active)
          dom && dom.scrollIntoView()
        })
      }
    }
  },
  created () {
    if (location.hostname !== 'localhost') {
      this.showAd = true
    }
  },
  methods: {
    login () {
      localStorage.setItem('redirect', '/')
      const { oauth_uri: oauthUri, client_id: clientId, redirect_uri: redirectUri } = oauthProvider.github
      location.href = `${oauthUri}?client_id=${clientId}&redirect_uri=${redirectUri}`
    }
  }
}
</script>

<style scoped lang="less">
.main {
  flex: 1;
  background: #f1f5f8;
  @media screen and (max-width: @xs) {
    padding-top: @top-bar-height;
    height: 100%;
    box-sizing: border-box;
  }
}
.el-scrollbar {
  height: 100%;
  :deep(.el-scrollbar__wrap) {
    overflow-x: hidden;
  }
}

.content {
  margin: 0 auto;
  width: 1112px;
  margin-top: 20px;
}

.item {
  min-height: 100px;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
  .title {
    font-size: 17px;
    color: #555;
    margin: 0 15px;
    padding: 15px 0;
    border-bottom: 1px solid #f1f5f8;
  }
  .web-item {
    float: left;
    box-sizing: border-box;
    padding: 10px;
    width: 25%;
  }
}

@media screen and (max-width: 1400px) {
  .content {
    width: 828px;
  }
  .item .web-item {
    width: 33.33%;
  }
}
@media screen and (max-width: 1150px) {
  .content {
    width: 544px;
  }
  .item .web-item {
    width: 50%;
  }
}
@media screen and (max-width: 632px) {
  .content {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .item .web-item {
    width: 100%;
  }
}
</style>
