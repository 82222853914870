<template>
  <div class="menu" :class="{ collapse: isCollapse }">
    <div class="top-bar">
      <div class="menu-btn" @click="changeMenu()">
        <i v-if="isCollapse" class="iconfont iconfont-menu" />
        <i v-else class="iconfont iconfont-close" />
      </div>
      <div class="logo">
        <el-image :src="require('@/assets/logo.png')" @click="gohome" />
      </div>
    </div>

    <div class="v-modal" />
    <el-scrollbar>
      <!-- <div class="logo">
        <el-image :src="require('@/assets/logo.png')" />
      </div> -->
      <el-menu :default-active="active" :unique-opened="true">
        <template v-for="item in menu">
          <el-submenu
            v-if="item.children && item.children.length > 0"
            :key="item.id"
            :index="String(item.id)"
          >
            <template slot="title">
              <i :class="`iconfont-${item.icon}`" class="icon iconfont" />
              <span>{{ item.name }}</span>
            </template>
            <el-menu-item
              v-for="child in item.children"
              :key="child.id"
              :index="'#' + child.name"
              @click="menuClick(child)"
            >
              <span>{{ child.name }}</span>
            </el-menu-item>
          </el-submenu>
          <el-menu-item
            v-else
            :key="item.id"
            :index="'#' + item.name"
            @click="menuClick(item)"
          >
            <i :class="`iconfont-${item.icon}`" class="icon iconfont" />
            <span>{{ item.name }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>
<script>
import { Image, Scrollbar, Menu, Submenu, MenuItem } from 'element-ui'
export default {
  components: {
    [Image.name]: Image,
    [Scrollbar.name]: Scrollbar,
    [Menu.name]: Menu,
    [Submenu.name]: Submenu,
    [MenuItem.name]: MenuItem
  },
  props: {
    menu: {
      type: Array,
      default: () => {}
    },
    active: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isCollapse: true
    }
  },

  methods: {
    changeMenu (flag) {
      if (flag !== undefined) {
        this.isCollapse = flag
      } else {
        this.isCollapse = !this.isCollapse
      }
    },
    menuClick (target) {
      const url = new URL(location.href)
      url.hash = target.name
      history.replaceState(null, null, url.toString())
      this.isCollapse = true

      const dom = document.querySelector('#' + target.name)
      dom && dom.scrollIntoView({
        behavior: 'smooth'
      })
    },
    gohome () {
      location.href = '/'
    }
  }
}
</script>
<style scoped lang="less">
.menu {
  width: @menu-width;
  .v-modal {
    display: none;
  }
  @media screen and (max-width: @xs) {
    position: absolute;
    width: 100%;
    z-index: 1;
    .v-modal {
      position: fixed;
      display: block;
      left: 0;
      top: @top-bar-height;
      width: 100%;
      height: calc(100% - @top-bar-height);
      opacity: 0.5;
      background: @bg-color-black;
      transition: all 0.5s;
    }
    .el-scrollbar {
      position: relative;
      top: 0;
      height: auto;
      max-height: calc(100% - @top-bar-height);
      width: 100%;
      margin: 0;
      :deep(.el-scrollbar__wrap) {
        margin-bottom: 0 !important;
        margin-right: 0 !important;
        padding: 0 20px 20px;
      }
    }

    &.collapse {
      .v-modal {
        opacity: 0;
        pointer-events: none;
      }
      .el-scrollbar {
        height: 0px;
      }
    }
  }
}

.top-bar {
  display: none;
  background: @bg-color-white;
  @media screen and (max-width: @xs) {
    display: flex;
  }
  height: @top-bar-height;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 20px 0 #d4dee6;
  .menu-btn {
    position: absolute;
    left: 10px;
    i {
      font-size: 25px;
    }
  }
  .logo {
    width: 30px;
    height: 30px;
  }
}

.el-scrollbar {
  background: @bg-color-white;
  position: absolute;
  width: 180px;
  left: 0;
  top: 0;
  height: 100%;
  margin: 0 20px;
  :deep(.el-scrollbar__wrap) {
    overflow-x: hidden;
  }
  .icon {
    margin-right: 10px;
  }
}

.el-menu {
  height: 100%;
  border: none;
  .el-menu-item {
    height: @menu-height;
    line-height: @menu-height;
    min-width: 0;
    &.is-active,
    &:hover {
      background: #f1f5f8;
      i,
      span {
        color: @menu-text-color-active;
      }
    }
    i,
    span {
      transition: all 0.5s;
      color: @menu-text-color;
    }
  }
  :deep(.el-submenu) {
    .el-submenu__title {
      height: @menu-height;
      line-height: @menu-height;
      i,
      span {
        color: @menu-text-color;
        transition: all 0.5s;
      }
      &:hover {
        background: #f1f5f8;
        i,
        span {
          color: @menu-text-color-active;
        }
      }
    }
  }
}
</style>
