<template>
  <div class="card">
    <div class="card-content">
      <a :href="item.url" target="_blank" :aria-label="item.name">
        <el-image
          class="logo"
          lazy
          :src="item.logo"
          fit="contain"
          referrerpolicy="no-referrer"
          :alt="item.name"
        >
          <div slot="error" class="image-error">{{ item.name.slice(0, 1) }}1</div>
        </el-image>
      </a>
      <div class="card-main">
        <p class="name overflowClip_1">
          <a :href="item.url" target="_blank" :aria-label="item.name"><strong>{{ item.name }}</strong></a>
        </p>
        <el-tooltip effect="dark" :content="item.desc" placement="bottom">
          <p class="desc overflowClip_2">{{ item.desc }}</p>
        </el-tooltip>
      </div>
    </div>
    <!-- <div class="card-count">😄</div> -->
  </div>
</template>

<script>
import { Image, Tooltip } from 'element-ui'
export default {
  components: {
    [Image.name]: Image,
    [Tooltip.name]: Tooltip
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="less" scoped>
.card {
  position: relative;
  border-radius: 4px;
  height: 51px;
  padding: 15px;
  background: #f8fafd;
  // border: 1px solid #e4ecf3;
  transition: all 0.3s ease;
  &:hover {
    // transform: translateY(-6px);
    box-shadow: 0 13px 40px -24px #0024644d;
  }
  .card-content {
    display: flex;

    :deep(.logo) {
      width: 32px;
      height: 32px;
      margin-right: 10px;
      img {
        width: 32px;
        height: 32px;
      }
      .image-error {
        width: 32px;
        height: 32px;
        background: #e9e9e9;
        font-size: 20px;
        text-align: center;
        line-height: 32px;
      }
    }
    .card-main {
      flex: 1;
      .name,
      .desc {
        font-size: 13px;
        margin: 0;
      }
      .desc {
        color: #979898;
        width: fit-content;
      }
    }
    a {
      text-decoration: none;
      color: #3d3d3d;
      &:hover {
        color: #00a4ec;
      }
    }
  }

  .card-count {
    color: #979898;
    display: flex;
    justify-content: flex-end;
  }
}

.overflowClip_1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.overflowClip_2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
